import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { fontWeights, white, linkVisited } from '@salutejs/plasma-tokens-b2c';
import { useTranslation } from 'next-i18next';
import { spacing } from '@salutejs/plasma-core';
import { TextL } from '@salutejs/plasma-b2c';

import { Image } from '../Image/Image';
import { desktopSize, media } from '../../const/tokens';
import { LocSwitchAdaptive } from '../LocSwitch/LocSwitchAdaptive';
import { Link } from '../Link/Link';
import { routesConfig } from '../../configs/routes';

const StyledContainer = styled.div`
    max-width: ${desktopSize}px;
    margin: auto;
    width: 100%;
    display: flex;
    flex-flow: row;
    padding: 54px 96px 0;
    z-index: 1;
    color: ${white};
    font-size: 32px;
    font-weight: 500;
    align-items: center;
    white-space: nowrap;

    ${media.tablet} {
        padding: 32px 48px 0;
    }

    ${media.mobile} {
        font-size: 16px;
        padding: 24px 24px 0;
    }
`;

const StyledLogo = styled(Image)`
    height: 26px;
    margin-right: -4px;

    ${media.mobile} {
        width: 100px;
        height: 20px;
    }
`;

const StyledBetaLabel = styled.div`
    font-family: 'SB Sans Display', sans-serif;
    font-weight: ${fontWeights.Bold};
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.01em;
    color: #000;
    background: #f5f5f5;
    border-radius: 4px;
    padding: 2px 8px 0;
    margin-left: 12px;
    height: 26px;

    ${media.mobile} {
        height: 22px;
        font-size: 12px;
        line-height: 14px;
        padding: 4px 5px;
        margin-left: 8px;
    }
`;

const StyledLoginLink = styled(Link)`
    display: inline-block;
    font-weight: ${fontWeights.Semibold};
    font-size: 24px;
    line-height: 40px;
    letter-spacing: -0.03em;
    text-decoration: none;
    color: ${white};

    &:hover {
        color: ${linkVisited};
    }

    ${media.tablet} {
        font-size: 20px;
        line-height: 40px;
        letter-spacing: normal;
    }

    ${media.mobile} {
        display: inline-block;
        font-weight: ${fontWeights.Semibold};
        font-size: 18px;
        line-height: 20px;
        text-decoration: none;
        color: ${white};
    }
`;

const StyledText = styled(TextL)`
    margin-left: auto;
    font-weight: ${fontWeights.Semibold};
    text-align: right;
    margin-right: ${spacing[32]};
    cursor: pointer;

    &:hover {
        color: ${linkVisited};
    }

    ${media.tablet} {
        font-size: 20px;
    }

    ${media.mobile} {
        font-size: 18px;
        margin-right: ${spacing[16]};
    }
`;

const StyledLocSwitch = styled(LocSwitchAdaptive)<{ arVrLanding?: boolean }>`
    margin-left: ${({ arVrLanding }) => (arVrLanding ? 'auto' : '36px')};

    ${media.mobile} {
        margin-left: ${({ arVrLanding }) => (arVrLanding ? 'auto' : spacing['16'])};
    }
`;

interface HeaderProps {
    onLogin: () => void;
    onTariffsClick?: () => void;
    children?: ReactNode;
    arVrLanding?: boolean;
}

export const Header: FC<HeaderProps> = ({ children, onLogin, onTariffsClick, arVrLanding }) => {
    const { t } = useTranslation('index');

    return (
        <StyledContainer>
            <StyledLogo fileName="logo-no-beta.svg" />
            {children}
            {!arVrLanding && (
                <StyledText data-e2e="tariffsLink" onClick={onTariffsClick}>
                    {t('Тарифы')}
                </StyledText>
            )}

            {!arVrLanding && (
                <StyledLoginLink href={routesConfig.login} onClick={onLogin} data-e2e="loginButton">
                    {t('Войти')}
                </StyledLoginLink>
            )}
            <StyledLocSwitch arVrLanding={arVrLanding} />
        </StyledContainer>
    );
};
