import styled from 'styled-components';

import { withCdnUrl } from '../../utils/static';

export const LandingContainer = styled.div`
    background-color: #000;
    background-image: url(${withCdnUrl('images/background.png')});
    background-position: center bottom 300px;
    background-repeat: no-repeat;
`;
