import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';

import { media } from '../../const/tokens';
import { withCdnUrl } from '../../utils/static';

export interface HorSectionProps {
    progress?: number;
    totalProgress?: number;
}

const StyledContainer = styled.div`
    position: relative;
    max-width: 400px;
    width: 400px;
    height: 576px;
    overflow: hidden;

    ${media.tablet} {
        width: 241px;
        height: 372px;
    }

    ${media.mobile} {
        width: 196px;
        height: 290px;
    }
`;

const StyledBG = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    width: 400px;
    height: 576px;
    background: #000;
    border-radius: 24px;

    ${media.tablet} {
        width: 100%;
        height: 100%;
    }

    ${media.mobile} {
        width: 100%;
        height: 100%;
    }
`;

const StyledPreview = styled.img`
    position: absolute;
    top: 0;
    right: 0;
    width: 400px;
    height: 100%;
    object-fit: contain;
    border-radius: 24px;

    ${media.tablet} {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    ${media.mobile} {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;

const StyledPlayerWrap = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    width: 400px;
    height: 100%;
    border-radius: 24px;
    overflow: hidden;

    ${media.tablet} {
        width: 100%;
        height: 100%;
    }

    ${media.mobile} {
        width: 100%;
        height: 100%;
    }

    & video {
        object-fit: cover;
        object-position: center;
    }
`;

interface CharacterPreviewProps {
    videoPreview?: string;
    preview: string;
    style?: CSSProperties;
    onPause?(): void;
    onClick?: () => void;
}

export const CharacterPreview: React.FC<CharacterPreviewProps> = ({
    videoPreview,
    preview,
    style,
    onPause,
    onClick,
    ...props
}) => {
    return (
        <StyledContainer style={style} onClick={onClick} {...props}>
            <StyledBG />
            <StyledPreview src={withCdnUrl(preview)} />
            {videoPreview && (
                <StyledPlayerWrap>
                    <ReactPlayer url={videoPreview} playing width="100%" height="100%" onPause={onPause} playsinline />
                </StyledPlayerWrap>
            )}
        </StyledContainer>
    );
};
