import React, { useCallback, useContext } from 'react';
import styled from 'styled-components';
import { buttonSecondary, white } from '@salutejs/plasma-tokens-b2c';
import { useRouter } from 'next/router';
import cookies from 'js-cookie';
import { i18n } from 'next-i18next';
import { LOCALE_COOKIE } from 'const/cookie';
import { useToast } from 'hooks/useToast';
import { TranslationsLanguagesEnum } from '@sberdevices/vc-contracts';

import { Link } from '../Link/Link';
import { putUser } from '../../api/user';
import { UserContext } from '../../store/UserContext';
import { getCurrentLocale } from '../../utils/locale';
import { fireLocaleUpdateEvent } from '../../hooks/useLocale';

interface StyledContainerProps {
    $currentLocale?: string;
}

const StyledContainer = styled(Link)<StyledContainerProps>`
    position: relative;
    width: 56px;
    height: 32px;
    background: ${buttonSecondary};
    border-radius: 16px;
    padding: 3px;
    cursor: pointer;
    display: flex;
    flex-flow: row;
    text-decoration: none;

    &::before {
        content: ' ';
        display: block;
        position: absolute;
        top: 3px;
        left: 3px;
        height: 26px;
        width: 26px;
        border-radius: 100%;
        background-color: ${white};
        transition: transform 0.2s linear;
    }

    &[data-locale='en']::before {
        transform: translate(24px, 0);
    }
`;

interface LocSwitchVariantProps {
    $active: boolean;
}

export const LocSwitchVariant = styled.span<LocSwitchVariantProps>`
    position: relative;
    display: block;
    height: 26px;
    width: 26px;
    text-align: center;
    padding-top: 6px;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.03em;
    color: ${({ $active }) => ($active ? '#1F1F1F' : white)};
    transition: color 0.2s linear;
`;

interface LocSwitchProps {
    className?: string;
}

export const LocSwitch: React.FC<LocSwitchProps> = ({ className }) => {
    const router = useRouter();
    const { user, setUser } = useContext(UserContext);
    const { showToast } = useToast();
    const currentLocale = getCurrentLocale(router, user);
    const newLocale =
        currentLocale === TranslationsLanguagesEnum.Ru ? TranslationsLanguagesEnum.En : TranslationsLanguagesEnum.Ru;

    const handleClick = useCallback(
        async (event) => {
            event.preventDefault();
            event.stopPropagation();

            if (user) {
                putUser({ language: newLocale })
                    .then(async (newUser) => {
                        setUser(newUser);
                        cookies.set(LOCALE_COOKIE, newLocale);
                        await router.replace(router.asPath, undefined, {
                            locale: newLocale,
                            shallow: true,
                            scroll: true,
                        });
                        await i18n?.changeLanguage(newLocale);
                        fireLocaleUpdateEvent(newLocale);
                    })
                    .catch(() => showToast.error('Ошибка переключения языка'));
            } else {
                cookies.set(LOCALE_COOKIE, newLocale);
                await router.replace(router.asPath, undefined, { locale: newLocale, shallow: true, scroll: true });
                await i18n?.changeLanguage(newLocale);
                fireLocaleUpdateEvent(newLocale);
            }
        },
        [newLocale, showToast, router, setUser, user],
    );

    return (
        <StyledContainer
            href={router.asPath}
            className={className}
            $currentLocale={currentLocale}
            locale={newLocale}
            onClick={handleClick}
            data-locale={currentLocale}
            scroll={false}
            shallow
        >
            <LocSwitchVariant $active={currentLocale === 'ru'}>Ru</LocSwitchVariant>
            <LocSwitchVariant $active={currentLocale === 'en'}>En</LocSwitchVariant>
        </StyledContainer>
    );
};
