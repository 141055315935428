import React, { useCallback } from 'react';
import styled from 'styled-components';
import { white } from '@salutejs/plasma-tokens-b2c';

import { Icon } from '../Icon/Icon';
import { media } from '../../const/tokens';
import { AdaptiveButton } from '../Button/AdaptiveButton';

const StyledButton = styled(AdaptiveButton)`
    background-color: #000;
    margin-right: 8px;
    display: flex;
    flex-flow: row;
    align-items: center;

    ${media.mobile} {
        height: 40px;
        font-size: 14px;
        padding: 0 24px 0 20px;
    }
`;

const StyledIcon = styled(Icon)`
    vertical-align: top;
    margin-left: -8px;

    ${media.mobile} {
        margin-left: -2px;
        margin-right: 4px;
        width: 24px !important;
        height: 24px !important;

        & svg {
            width: 24px;
            height: 24px;
        }
    }
`;

interface VoiceButtonProps {
    label: string;
    value: string;
    activeValue?: string;
    onClick(value: string | undefined): void;
}

export const VoiceButton: React.FC<VoiceButtonProps> = ({ label, value, activeValue, onClick }) => {
    const click = useCallback(() => onClick(activeValue === value ? undefined : value), [activeValue, onClick, value]);

    return (
        <StyledButton data-e2e="voiceButton" onClick={click}>
            <StyledIcon icon="voice" height={40} width={40} color={activeValue === value ? '#3F81FD' : white} />
            {label}
        </StyledButton>
    );
};
