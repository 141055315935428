import styled from 'styled-components';

import { Link } from '../Link/Link';

export const LandingLink = styled(Link)`
    letter-spacing: -0.03em;
    text-decoration: none;
    color: #2364de;

    &:hover {
        filter: brightness(120%);
    }
`;
