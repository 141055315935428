import React from 'react';

import { withCdnUrl } from '../../utils/static';

interface StyledImageProps {
    fileName: string;
    height?: string;
    width?: string;
    className?: string;
}

export const Image: React.FC<StyledImageProps> = ({ fileName, ...props }) => {
    return <img alt="" src={withCdnUrl(`images/${fileName}`)} {...props} />;
};
