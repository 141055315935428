import styled from 'styled-components';
import React from 'react';
import { fontWeights, primary, white } from '@salutejs/plasma-tokens-b2c';
import { useTranslation } from 'next-i18next';

import { desktopSize, media } from '../../const/tokens';
import { Icon } from '../Icon/Icon';
import { TELEGRAM_BOT_LINK } from '../../const/externalResources';

import { LandingLink } from './LandingLink';

const StyledContainer = styled.div`
    display: flex;
    flex-flow: row;
    max-width: ${desktopSize}px;
    margin: 0 auto;
    padding: 0 96px 34px;
    align-items: center;

    ${media.tablet} {
        display: block;
        flex-flow: inherit;
        padding: 0;
    }

    ${media.mobile} {
        display: block;
        flex-flow: inherit;
        padding: 0 24px 22px;
    }
`;

const StyledFooterText = styled.div`
    font-weight: ${fontWeights.Regular};
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.03em;
    user-select: none;
    color: #9ba4c7;
    max-width: 654px;
    text-align: left;

    ${media.tablet} {
        text-align: center;
        max-width: 414px;
        margin: 0 auto;
        font-size: 24px;
        margin-bottom: 34px;
    }

    ${media.mobile} {
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        margin: 0 auto;
        padding: 0 0 34px;
    }
`;

const StyledPolicyLink = styled(LandingLink)`
    display: block;
`;

const StyledIcon = styled(Icon)`
    margin-left: 16px;
    margin-top: 6px;
`;

const StyledTGLink = styled.a`
    display: flex;
    flex-flow: row;
    justify-content: center;
    margin-left: auto;
    height: 80px;
    background: #1f1f1f;
    border-radius: 16px;
    padding: 20px 32px;
    color: ${primary};
    letter-spacing: -0.03em;
    font-size: 24px;
    line-height: 40px;
    font-weight: ${fontWeights.Semibold};
    cursor: pointer;
    text-decoration: none;
    text-align: center;
    transition: all 0.15s linear;

    ${media.tablet} {
        border-radius: 0;
        font-size: 20px;
    }

    ${media.mobile} {
        background: #262626;
        border-radius: 8px;
        font-size: 16px;
        line-height: 24px;
        color: ${white};
        height: 56px;
        padding: 16px 48px;

        ${StyledIcon} {
            height: 21px !important;
            width: 26px !important;
            margin-top: 2px;
            margin-left: 8px;

            & svg {
                height: 21px;
                width: 26px;
            }
        }
    }

    &:hover {
        filter: brightness(120%);
    }
`;

export const Footer: React.FC = () => {
    const { t } = useTranslation('footer');

    return (
        <StyledContainer>
            <StyledFooterText>
                {t(
                    'Общество с ограниченной ответственностью «СалютДевайсы». Москва, Кутузовский проспект, дом 32, кабинет 6.24. ОГРН 1197746592394',
                )}
                <StyledPolicyLink href="https://sberdevices.ru/legal/privacy_policy/" target="_blank" rel="nofollow">
                    {t('Политика обработки и хранения данных')}
                </StyledPolicyLink>
            </StyledFooterText>

            <StyledTGLink href={TELEGRAM_BOT_LINK} target="_blank" rel="nofollow" data-e2e="telegramFooterLink">
                {t('Чат для вопросов')} <StyledIcon icon="telegram" width={34} height={28} />
            </StyledTGLink>
        </StyledContainer>
    );
};
