import styled from 'styled-components';
import React, { useCallback, useState } from 'react';
import { white } from '@salutejs/plasma-tokens-b2c';
import { Trans, useTranslation } from 'next-i18next';
import { spacing } from '@salutejs/plasma-core';

import { media } from '../../const/tokens';
import { Title } from '../Title/Title';
import { AuthError } from '../AuthError/AuthError';
import { AuthForm, AuthFormMode } from '../AuthForm/AuthForm';
import { LogotypeRoot } from '../Logotype/Logotype';

const StyledForm = styled(AuthForm)`
    width: 100%;
    max-width: 392px;
    margin-left: auto;
`;

const StyledTitle = styled(Title)`
    width: 620px;
    line-height: 64px;
    color: ${white};
    letter-spacing: -0.01em;
    margin-bottom: 32px;

    ${media.tablet} {
        font-size: 40px;
        line-height: 48px;
        width: 380px;
        display: none;
    }

    ${media.mobile} {
        width: 100%;
        font-size: 27px;
        line-height: 32px;
        display: none;
    }
`;

const StyledContainer = styled.div`
    margin: 0 auto 103px;
    padding: 112px 96px 0;
    max-width: 1440px;
    display: flex;
    flex-flow: row;

    ${media.tablet} {
        width: 413px;
        padding: 150px ${spacing[16]} 0;
        margin-bottom: 56px;
        flex-flow: column;
    }

    ${media.mobile} {
        width: 100%;
        padding: 150px ${spacing[16]} 24px;
        margin-bottom: 40px;
        flex-flow: column;
    }
`;

const StyledError = styled(AuthError)`
    ${LogotypeRoot} {
        display: none;
    }
`;

interface SignupSectionProps {
    onSignup: () => void;
}

export const SignupSection: React.FC<SignupSectionProps> = ({ onSignup }) => {
    const { t } = useTranslation('signup');
    const [hasError, setError] = useState(false);

    const handleError = useCallback(() => {
        setError(true);
    }, []);

    return !hasError ? (
        <StyledContainer id="landingSignup">
            <StyledTitle>
                <Trans t={t} i18nKey="Зарегистрируйтесь, <1/> чтобы создать ролик">
                    Зарегистрируйтесь, <br />
                    чтобы создать ролик
                </Trans>
            </StyledTitle>
            <StyledForm onSubmit={onSignup} onError={handleError} defaultMode={AuthFormMode.Reg} />
        </StyledContainer>
    ) : (
        <StyledError onClick={() => setError(false)} />
    );
};
