import React, { FC } from 'react';
import styled from 'styled-components';
import { white } from '@salutejs/plasma-tokens-b2c';

import { media } from '../../const/tokens';
import { Title } from '../Title/Title';
import { Text } from '../Text/Text';

import { Container } from './Container';

const StyledMakeVideoHeader = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 56px;

    ${media.mobile} {
        grid-template-columns: 1fr;
        grid-template-rows: 74px 1fr;
        flex-direction: column;
        align-items: center;
        align-self: center;
        margin-bottom: 32px;
        width: 100%;
    }
`;

const StyledMakeVideoHeaderTitle = styled(Title)`
    max-width: 410px;
    color: ${white};

    ${media.tablet} {
        padding-right: 35px;
    }

    ${media.mobile} {
        align-self: flex-start;
        width: 100%;
        margin-bottom: 8px;
    }
`;

const StyledDescription = styled(Text)`
    max-width: 612px;
    color: ${white};
    counter-reset: descriptionItem;

    ${media.tablet} {
        width: 100%;
    }

    ${media.mobile} {
        width: 100%;
    }

    ol {
        margin: 0;
        padding-left: 22px;
    }

    li:not(:last-child) {
        margin-bottom: 24px;

        ${media.tablet} {
            margin-bottom: 8px;
        }

        ${media.mobile} {
            margin-bottom: 8px;
        }
    }
`;

interface MakeItEasySectionProps {
    title: string;
    description: any;
    children: any;
}

export const MakeItEasySection: FC<MakeItEasySectionProps> = ({ title, description, children, ...props }) => {
    return (
        <Container id="landingMakeItEasy" {...props}>
            <StyledMakeVideoHeader>
                <StyledMakeVideoHeaderTitle>{title}</StyledMakeVideoHeaderTitle>
                <StyledDescription>{description}</StyledDescription>
            </StyledMakeVideoHeader>

            {children}
        </Container>
    );
};
