import styled from 'styled-components';
import React from 'react';
import { white } from '@salutejs/plasma-tokens-b2c';
import { useTranslation } from 'next-i18next';

import { Title } from '../Title/Title';
import { Text } from '../Text/Text';
import { AdaptiveButton } from '../Button/AdaptiveButton';
import { media } from '../../const/tokens';
import { Logotype } from '../Logotype/Logotype';

const StyledContainer = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

const StyledTitle = styled(Title)`
    color: ${white};
    margin-bottom: 8px;
`;

const StyledDescription = styled(Text)`
    color: ${white};
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 32px;

    ${media.tablet} {
        font-size: 20px;
    }

    ${media.mobile} {
        font-size: 16px;
        line-height: 24px;
    }
`;

const StyledButton = styled(AdaptiveButton)`
    background-color: #1f1f1f;
`;

export const AuthError: React.FC<{ onClick: () => void }> = ({ onClick, ...props }) => {
    const { t } = useTranslation('authError');

    return (
        <StyledContainer {...props}>
            <Logotype />
            <StyledTitle>{t('Что-то пошло не так')}</StyledTitle>
            <StyledDescription>{t('Попробуйте снова чуть позже')}</StyledDescription>
            <StyledButton onClick={onClick}>{t('Хорошо, спасибо')}</StyledButton>
        </StyledContainer>
    );
};
