import React, { FC } from 'react';
import styled from 'styled-components';

import { desktopSize, media } from '../../const/tokens';

const StyledContainer = styled.section`
    max-width: ${desktopSize}px;
    margin: auto;
    padding: 0 32px;

    ${media.tablet} {
        padding: 0;
    }

    ${media.mobile} {
        padding: 0;
    }
`;

const StyledMakeVideoContainer = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    background: #092793;
    border-radius: 32px;
    padding: 78px 64px;
    position: relative;

    ${media.tablet} {
        padding: 61px 48px;
        border-radius: 0;
    }

    ${media.mobile} {
        padding: 24px;
        border-radius: 0;
    }
`;

interface MakeItEasySectionProps {
    children: any;
    id?: string;
}

export const Container: FC<MakeItEasySectionProps> = ({ children, ...props }) => {
    return (
        <StyledContainer {...props}>
            <StyledMakeVideoContainer>{children}</StyledMakeVideoContainer>
        </StyledContainer>
    );
};
