function scrollTo(id: string): void {
    const element = document.getElementById(id);

    if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
    }
}

export const tryNow = (cb: (id: string) => void) => {
    return (e?: any) => {
        if (e?.nativeEvent?.target) {
            const section = e.nativeEvent.target.closest('[id]');

            cb(section.id);
        }

        scrollTo('landingSignup');
    };
};
