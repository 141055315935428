import styled from 'styled-components';
import { fontWeights } from '@salutejs/plasma-tokens-b2c';

import { media } from '../../const/tokens';

export const Title = styled.div`
    font-family: 'SB Sans Display', sans-serif;
    font-size: 56px;
    line-height: 64px;
    font-weight: ${fontWeights.Semibold};
    letter-spacing: -0.01em;

    ${media.tablet} {
        font-size: 40px;
        line-height: 48px;
        letter-spacing: normal;
    }

    ${media.mobile} {
        font-size: 27px;
        line-height: 32px;
        letter-spacing: normal;
    }
`;
