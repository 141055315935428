import styled from 'styled-components';

import { media } from '../../const/tokens';

import { LocSwitch, LocSwitchVariant } from './LocSwitch';

export const LocSwitchAdaptive = styled(LocSwitch)`
    width: 104px;
    height: 56px;
    border-radius: 28px;
    padding: 4px;

    &::before {
        top: 4px;
        left: 4px;
        height: 48px;
        width: 48px;
    }

    &[data-locale='en']::before {
        transform: translate(100%, 0);
    }

    ${LocSwitchVariant} {
        height: 48px;
        width: 48px;
        padding-top: 4px;
        font-size: 16px;
        line-height: 40px;
    }

    ${media.tablet} {
        width: 114px;
        height: 64px;
        border-radius: 32px;
        padding: 8px;

        &::before {
            top: 8px;
            left: 8px;
        }

        &[data-locale='en']::before {
            transform: translate(50px, 0);
        }
    }

    ${media.mobile} {
        width: 56px;
        height: 32px;
        border-radius: 16px;
        padding: 3px;

        &::before {
            top: 3px;
            left: 3px;
            height: 26px;
            width: 26px;
        }

        &[data-locale='en']::before {
            transform: translate(24px, 0);
        }

        ${LocSwitchVariant} {
            height: 26px;
            width: 26px;
            padding-top: 6px;
            font-size: 12px;
            line-height: 14px;
        }
    }
`;
