import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { black, white } from '@salutejs/plasma-tokens-b2c';

import { Icon } from '../../components/Icon/Icon';
import { media } from '../../const/tokens';
import { VideoPlayer } from '../../components/VideoPlayer/VideoPlayer';

const StyledView = styled(VideoPlayer)`
    position: relative;
    border-radius: 32px;
    overflow: hidden;
    mask-image: radial-gradient(${white}, ${black});

    ${media.mobile} {
        border-radius: 16px;
    }
`;

const StyledPlayBtnContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
`;

const StyledPlayBtn = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const StyledIcon = styled(Icon)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &,
    svg {
        width: 180px !important;
        height: 180px !important;
    }

    ${media.tablet} {
        &,
        svg {
            width: 100px !important;
            height: 100px !important;
        }
    }

    ${media.mobile} {
        &,
        svg {
            width: 48px !important;
            height: 48px !important;
        }
    }
`;

interface VideoProps {
    url: string;
    autoPlay?: boolean;
}

export const Video: React.FC<VideoProps> = ({ url, autoPlay = false, ...props }) => {
    const [playing, setPlaying] = useState(autoPlay);

    const onPlay = useCallback(() => {
        setPlaying(true);
    }, []);

    const onPause = useCallback(() => {
        setPlaying(false);
    }, []);

    return (
        <StyledView playing={playing} onPause={onPause} url={url} showFullscreen={false} {...props}>
            <StyledPlayBtnContainer onClick={playing ? onPause : onPlay}>
                <StyledPlayBtn>
                    <StyledIcon icon={playing ? 'pause' : 'play'} color={white} />
                </StyledPlayBtn>
            </StyledPlayBtnContainer>
        </StyledView>
    );
};
