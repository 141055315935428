import styled from 'styled-components';
import { fontWeights } from '@salutejs/plasma-tokens-b2c';

import { media } from '../../const/tokens';

export const Text = styled.div`
    font-weight: ${fontWeights.Medium};
    font-size: 32px;
    line-height: 48px;
    letter-spacing: -0.03em;

    ${media.tablet} {
        font-size: 20px;
        line-height: 32px;
        letter-spacing: normal;
    }

    ${media.mobile} {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: normal;
    }
`;
